import React from "react";
import "./index.scss";

import hand_carfted_1 from "./assets/hand_carfted_1.mp4";
import hand_carfted_2 from "./assets/hand_carfted_2.mp4";
import hand_carfted_3 from "./assets/hand_carfted_3.mp4";

let SquareBites = () => {
  return (
    <>
      <div
        id="divider_id"
        className="website-divider-container-27295 hflip">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="divider-img-27295"
          viewBox="0 0 1080 200"
          preserveAspectRatio="none">
          <path d="M 0,100 C 51.208549,93.32062 78.809127,7.2350839 182.56961,4.0716406 293.00946,-1.3182614 349.63298,138.16937 446.48874,139.88562 540,139.37541 544.656,48.99481 627.86135,46.374354 702.71039,44.01707 732.31768,168.6649 828.48844,165.91877 909.3509,163.60977 972.01675,54.783519 1080,163.88 V 200 H 0 Z"></path>
        </svg>
      </div>
      <section id="square-bites">
        <h1 id="hand_crafted">Hand Crafted</h1>
        <div id="our_flagship">
          Our flagship products, including the renowned Knafeh
          and Baklava, showcase superior craftsmanship and
          time-honored recipes.
        </div>
        <div id="our_handcrafted">
          Our handcrafted Knafeh, a beloved Middle Eastern
          dessert, features delicate layers of shredded phyllo
          dough, creamy cheese filling, and fragrant syrup.
        </div>
        <div id="each_piece">
          Each piece is meticulously made by hand, ensuring a
          perfect blend of textures and flavors.
        </div>
        <div id="hand_carfted_1">
          <video
            width={"100%"}
            autoPlay={"yes"}
            muted={"yes"}
            loop={"yes"}>
            <source src={hand_carfted_1} />
          </video>
        </div>
        <div id="hand_carfted_2">
          <video
            width={"100%"}
            autoPlay={"yes"}
            muted={"yes"}
            loop={"yes"}>
            <source src={hand_carfted_2} />
          </video>
        </div>
        <div id="hand_carfted_3">
          <video
            width={"100%"}
            autoPlay={"yes"}
            muted={"yes"}
            loop={"yes"}>
            <source src={hand_carfted_3} />
          </video>
        </div>
      </section>
      <div
        id="divider_id"
        className="website-divider-container-27295">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="divider-img-27295 vflip"
          viewBox="0 0 1080 200"
          preserveAspectRatio="none">
          <path d="M 0,100 C 51.208549,93.32062 78.809127,7.2350839 182.56961,4.0716406 293.00946,-1.3182614 349.63298,138.16937 446.48874,139.88562 540,139.37541 544.656,48.99481 627.86135,46.374354 702.71039,44.01707 732.31768,168.6649 828.48844,165.91877 909.3509,163.60977 972.01675,54.783519 1080,163.88 V 200 H 0 Z"></path>
        </svg>
      </div>
    </>
  );
};

export default SquareBites;
